import React, { Component } from "react";

import Home from "./containers/Home/Home";

class App extends Component {

  render() {

    return (
      <Home />
    );
  }
}

export default App;
