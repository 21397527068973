import d20Dist from "./D20Distributions/1d20_distribution";
import d20AdvDist from "./D20Distributions/1d20_adv_distribution";
import d20DisadvDist from "./D20Distributions/1d20_disadv_distribution";

import d201d4Dist from "./D20Distributions/1d20_1d4_distribution";
import d201d4AdvDist from "./D20Distributions/1d20_1d4_adv_distribution";
import d201d4DisadvDist from "./D20Distributions/1d20_1d4_disadv_distribution";

import d202d4Dist from "./D20Distributions/1d20_2d4_distribution";
import d202d4AdvDist from "./D20Distributions/1d20_2d4_adv_distribution";
import d202d4DisadvDist from "./D20Distributions/1d20_2d4_disadv_distribution";

import d20Neg1d4Dist from "./D20Distributions/1d20_neg1d4_distribution";
import d20Neg1d4AdvDist from "./D20Distributions/1d20_neg1d4_adv_distribution";
import d20Neg1d4DisadvDist from "./D20Distributions/1d20_neg1d4_disadv_distribution";

import d201d6Dist from "./D20Distributions/1d20_1d6_distribution";
import d201d6AdvDist from "./D20Distributions/1d20_1d6_adv_distribution";
import d201d6DisadvDist from "./D20Distributions/1d20_1d6_disadv_distribution";

import d201d8Dist from "./D20Distributions/1d20_1d8_distribution";
import d201d8AdvDist from "./D20Distributions/1d20_1d8_adv_distribution";
import d201d8DisadvDist from "./D20Distributions/1d20_1d8_disadv_distribution";

import d201d10Dist from "./D20Distributions/1d20_1d10_distribution";
import d201d10AdvDist from "./D20Distributions/1d20_1d10_adv_distribution";
import d201d10DisadvDist from "./D20Distributions/1d20_1d10_disadv_distribution";

import d201d12Dist from "./D20Distributions/1d20_1d12_distribution";
import d201d12AdvDist from "./D20Distributions/1d20_1d12_adv_distribution";
import d201d12DisadvDist from "./D20Distributions/1d20_1d12_disadv_distribution";

const distributions = {
  "1d20": {},
  "1d20+1d4": {},
  "1d20+2d4": {},
  "1d20-1d4": {},
  "1d20+1d6": {},
  "1d20+1d8": {},
  "1d20+1d10": {},
  "1d20+1d12": {}
};

distributions["1d20"]["default"] = d20Dist;
distributions["1d20"]["advantage"] = d20AdvDist;
distributions["1d20"]["disadvantage"] = d20DisadvDist;

distributions["1d20+1d4"]["default"] = d201d4Dist;
distributions["1d20+1d4"]["advantage"] = d201d4AdvDist;
distributions["1d20+1d4"]["disadvantage"] = d201d4DisadvDist;

distributions["1d20+2d4"]["default"] = d202d4Dist;
distributions["1d20+2d4"]["advantage"] = d202d4AdvDist;
distributions["1d20+2d4"]["disadvantage"] = d202d4DisadvDist;

distributions["1d20-1d4"]["default"] = d20Neg1d4Dist;
distributions["1d20-1d4"]["advantage"] = d20Neg1d4AdvDist;
distributions["1d20-1d4"]["disadvantage"] = d20Neg1d4DisadvDist;

distributions["1d20+1d6"]["default"] = d201d6Dist;
distributions["1d20+1d6"]["advantage"] = d201d6AdvDist;
distributions["1d20+1d6"]["disadvantage"] = d201d6DisadvDist;

distributions["1d20+1d8"]["default"] = d201d8Dist;
distributions["1d20+1d8"]["advantage"] = d201d8AdvDist;
distributions["1d20+1d8"]["disadvantage"] = d201d8DisadvDist;

distributions["1d20+1d10"]["default"] = d201d10Dist;
distributions["1d20+1d10"]["advantage"] = d201d10AdvDist;
distributions["1d20+1d10"]["disadvantage"] = d201d10DisadvDist;

distributions["1d20+1d12"]["default"] = d201d12Dist;
distributions["1d20+1d12"]["advantage"] = d201d12AdvDist;
distributions["1d20+1d12"]["disadvantage"] = d201d12DisadvDist;

export default distributions;
