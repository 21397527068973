import React from "react";

import classes from "./Banner.module.css";

const banner = () => (

  <>
    <h2 className={classes.Banner}>D&D Odds</h2>
    <h4 className={classes.Tagline}>Dice roller and probabilities for any roll or modifier. (5e only)</h4>
    <h4 className={classes.Subline}>Wow.</h4>
  </>
);

export default banner;
