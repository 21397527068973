import React, { Component } from "react";

import classes from "./Main.module.css";
import NavBar from "../../components/NavBar/NavBar";
import DiceRollerPanel from "../../components/Panels/DiceRollerPanel/DiceRollerPanel";
import D20Panel from "../../components/Panels/D20Panel/D20Panel";
import DamagePanel from "../../components/Panels/DamagePanel/DamagePanel";
import * as c from "../../resources/constants";
import * as validators from "../../resources/validators";
import * as calculators from "../../resources/calculators";

import lightning from "../../assets/lightning_bolt.png";
import bicep from "../../assets/bicep.png";
import wizHat from "../../assets/wizard_hat.png";
import d20 from "../../assets/d20.png";

import lightningLarge from "../../assets/lightning_bolt_large.png";
import bicepLarge from "../../assets/bicep_large.png";
import wizHatLarge from "../../assets/wizard_hat_large.png";
import d20Large from "../../assets/d20_large.png";

const maxNumberOfDiceMap = {
  "4": 15,
  "6": 12,
  "8": 10,
  "10": 9,
  "12": 8,
  "20": 1,
  "100": 1
};

const rolld20 = () => {
  return Math.floor(Math.random() * 20) + 1;
};

const rollxdy = (x, y, breakdown) => {

  let sum = 0;

  for (let i = 0; i < x; i++) {

    let roll = Math.floor(Math.random() * y) + 1;

    breakdown.push(roll);
    sum += roll;
  }

  return sum;
};

class Main extends Component {

  state = {
    activeNav: "diceRoller",

    diceRollerData: {
      numberOfDiceInput: 1,
      sidedDiceInput: 6,
      signInput: "+",
      modifierInput: "0",
      advInput: "default",
      rawResult1: "",
      rawResult2: "",
      result: "",
      resultBreakdown: "",
      showProbabilities: true,
      pdf: "",
      lessThanPercent: "",
      greaterThanPercent: "",
      numberOfDice: 1,
      sidedDice: 6,
      sign: "+",
      modifier: "0",
      numericModifier: 0,
      adv: "default",
      displayImage: false,
      img: null,
      lastClick: null
    },

    abilityChecksData: {
      type: "abilityChecks",
      statBonus: "+0",
      proficiencyBonus: "+2",
      otherBonus: "",
      totalMod: "+2",
      dc: "10",
      adv: "default",
      totalRoll: "12.5",
      success: "65.0%",
      isStatBonusError: false,
      isProficiencyBonusError: false,
      isOtherBonusError: false,
      isDcError: false,
      statBonusErrorMsg: "",
      proficiencyBonusErrorMsg: "",
      otherBonusErrorMsg: "",
      dcErrorMsg: "",
      displayImage: false,
      img: null,
      lastKeystroke: null
    },

    savingThrowsData: {
      type: "savingThrows",
      statBonus: "+0",
      proficiencyBonus: "+2",
      otherBonus: "",
      totalMod: "+2",
      dc: "10",
      adv: "default",
      totalRoll: "12.5",
      success: "65.0%",
      isStatBonusError: false,
      isProficiencyBonusError: false,
      isOtherBonusError: false,
      isDcError: false,
      statBonusErrorMsg: "",
      proficiencyBonusErrorMsg: "",
      otherBonusErrorMsg: "",
      dcErrorMsg: "",
      displayImage: false,
      img: null,
      lastKeystroke: null
    },

    attackRollsData: {
      type: "attackRolls",
      statBonus: "+0",
      proficiencyBonus: "+2",
      otherBonus: "",
      totalMod: "+2",
      ac: "10",
      adv: "default",
      totalRoll: "12.5",
      success: "65.0%",
      isStatBonusError: false,
      isProficiencyBonusError: false,
      isOtherBonusError: false,
      isAcError: false,
      statBonusErrorMsg: "",
      proficiencyBonusErrorMsg: "",
      otherBonusErrorMsg: "",
      acErrorMsg: "",
      displayImage: false,
      img: null,
      lastKeystroke: null
    },

    damageRollsData: {
      type: "damageRolls",
      damageDice: "",
      actualDamage: "",
      expectedDamage: "",
      dmgPDF: "",
      dmgProbMore: "",
      dmgProbLess: "",
      isDamageDiceError: false,
      isActualDamageError: false,
      damageDiceErrorMsg: "",
      actualDamageErrorMsg: "",
      displayImage: false,
      img: null,
      lastKeystroke: null
    }
  }

  updateNav = event => {

    this.setState({
      activeNav: event.target.id
    });
  }

  toggleProbabilities = () => {

    this.setState({

      diceRollerData: {
        ...this.state.diceRollerData,
        showProbabilities: !this.state.diceRollerData.showProbabilities
      }
    });
  };

  updateNumberOfDiceInput = event => {

    this.setState({

      diceRollerData: {
        ...this.state.diceRollerData,
        numberOfDiceInput: Number(event.target.value)
      }
    });
  }

  updateSidedDiceInput = event => {

    const sidedDice = Number(event.target.value);
    let numberOfDice = this.state.diceRollerData.numberOfDiceInput;
    const maxNumberOfDice = maxNumberOfDiceMap[String(sidedDice)];

    if (numberOfDice > maxNumberOfDice) {
      numberOfDice = maxNumberOfDice;
    }

    this.setState({

      diceRollerData: {
        ...this.state.diceRollerData,
        numberOfDiceInput: numberOfDice,
        sidedDiceInput: sidedDice
      }
    });
  }

  updateSignInput = event => {

    this.setState({

      diceRollerData: {
        ...this.state.diceRollerData,
        signInput: event.target.value
      }
    });
  }

  updateModifierInput = event => {

    this.setState({

      diceRollerData: {
        ...this.state.diceRollerData,
        modifierInput: event.target.value
      }
    });
  }

  updateAdv = event => {

    this.setState({

      diceRollerData: {
        ...this.state.diceRollerData,
        advInput: event.target.value
      }
    });
  }

  roll1d20 = () => {

    const adv = this.state.diceRollerData.advInput;
    const modInput = this.state.diceRollerData.modifierInput;
    const signInput = this.state.diceRollerData.signInput;

    let mod = Number(modInput);
    mod *= (signInput === "+") ? 1 : -1;

    const rawResult1 = rolld20();
    const result1 = rawResult1 + mod;
    let rawResult2;
    let result2;
    let totalResult;

    if (["advantage", "disadvantage"].includes(adv)) {

      rawResult2 = rolld20();
      result2 = rawResult2 + mod;

      if (adv === "advantage")
        totalResult = (result1 >= result2) ? result1 : result2;
      else
        totalResult = (result1 <= result2) ? result1 : result2;

    } else {
      totalResult = result1;
    }

    const pdf = calculators.d20PDF(totalResult - mod, adv);
    const cdf = calculators.d20CDF(totalResult - mod, adv);

    const rng = Math.random();
    let img;

    if (rng < 0.25)
      img = lightningLarge;
    else if (rng < 0.5)
      img = bicepLarge;
    else if (rng < 0.75)
      img = wizHatLarge;
    else
      img = d20Large;

    this.setState({

      diceRollerData: {
        ...this.state.diceRollerData,
        rawResult1: String(rawResult1),
        rawResult2: (rawResult2 !== undefined) ? String(rawResult2) : "",
        result: String(totalResult),
        resultBreakdown: "",
        pdf: (Math.max(pdf, 0) * 100).toFixed(1) + "%",
        lessThanPercent: (Math.max(cdf, 0) * 100).toFixed(1) + "%",
        greaterThanPercent: (Math.max(1 - cdf, 0) * 100).toFixed(1) + "%",
        numberOfDice: 1,
        sign: signInput,
        modifier: modInput,
        numericModifier: mod,
        adv: adv,
        displayImage: true,
        img: img,
        lastClick: new Date()
      }

    }, () => {

      setTimeout(() => {

        if ((new Date() - this.state.diceRollerData.lastClick) < 1500)
          return;

        this.setState({

          diceRollerData: {
            ...this.state.diceRollerData,
            displayImage: false
          }
        });

      }, 1500);
    });
  }

  rollxdy = () => {

    const numberOfDiceInput = this.state.diceRollerData.numberOfDiceInput;
    const sidedDiceInput = this.state.diceRollerData.sidedDiceInput;
    const modInput = this.state.diceRollerData.modifierInput;
    const signInput = this.state.diceRollerData.signInput;

    let mod = Number(modInput);
    mod *= (signInput === "+") ? 1 : -1;

    let resultBreakdown = [];
    const rawResult = rollxdy(numberOfDiceInput, sidedDiceInput, resultBreakdown);
    const totalResult = rawResult + mod;

    const pdf = calculators.xdyPDF(rawResult, numberOfDiceInput, sidedDiceInput);
    const cdf = calculators.xdyCDF(rawResult, numberOfDiceInput, sidedDiceInput);

    const rng = Math.random();
    let img;

    if (rng < 0.25)
      img = lightningLarge;
    else if (rng < 0.5)
      img = bicepLarge;
    else if (rng < 0.75)
      img = wizHatLarge;
    else
      img = d20Large;

    this.setState({

      diceRollerData: {
        ...this.state.diceRollerData,
        rawResult1: String(rawResult),
        rawResult2: "",
        result: String(totalResult),
        resultBreakdown: resultBreakdown.join(" + "),
        pdf: (Math.max(pdf, 0) * 100).toFixed(1) + "%",
        lessThanPercent: (Math.max(cdf, 0) * 100).toFixed(1) + "%",
        greaterThanPercent: (Math.max(1 - cdf, 0) * 100).toFixed(1) + "%",
        numberOfDice: numberOfDiceInput,
        sidedDice: sidedDiceInput,
        sign: signInput,
        modifier: modInput,
        numericModifier: mod,
        adv: "default",
        displayImage: true,
        img: img,
        lastClick: new Date()
      }

    }, () => {

      setTimeout(() => {

        if ((new Date() - this.state.diceRollerData.lastClick) < 1500)
          return;

        this.setState({

          diceRollerData: {
            ...this.state.diceRollerData,
            displayImage: false
          }
        });

      }, 1500);
    });
  };

  rollDice = () => {

    if (this.state.diceRollerData.sidedDiceInput === 20)
      this.roll1d20();
    else
      this.rollxdy();
  }

  updateTextInput = event => {

    const dataProp = this.state.activeNav + "Data";
    const input = event.target.value;
    const inputType = event.target.dataset.input;

    const errorStr = "is" + inputType.charAt(0).toUpperCase() + inputType.slice(1) + "Error";
    const errorMsgStr = inputType + "ErrorMsg";

    let isValid, errorMsg, validObj;

    if (["statBonus", "proficiencyBonus"].includes(inputType)) {

      let min, max;

      if (inputType === "statBonus") {

        min = c.STAT_MOD_MIN;
        max = c.STAT_MOD_MAX;

      } else {

        min = c.PROF_BONUS_MIN;
        max = c.PROF_BONUS_MAX;
      }

      validObj = validators.isValidBonus(input, min, max);

    } else if (inputType === "otherBonus") {
      validObj = validators.isValidOtherBonus(input);
    } else if (["ac", "dc"].includes(inputType)) {
      validObj = validators.isValidAcDc(input);
    } else if (inputType === "damageDice") {
      validObj = validators.isValidDamageDice(input);
    } else if (inputType === "actualDamage") {
      validObj = validators.isValidActualDamage(input);
    }

    isValid = validObj[0];
    errorMsg = validObj[1];

    const rng = Math.random();
    let img;

    if (rng < 0.25)
      img = lightning;
    else if (rng < 0.5)
      img = bicep;
    else if (rng < 0.75)
      img = wizHat;
    else
      img = d20;

    let blankFade = false;

    if (dataProp === "damageRollsData") {

      let isDamageDiceError, isActualDamageError;

      if (inputType === "damageDice") {

        isDamageDiceError = !isValid || (input === "");
        isActualDamageError = this.state[dataProp].isActualDamageError || (this.state[dataProp].actualDamage === "");

      } else {

        isDamageDiceError = this.state[dataProp].isDamageDiceError || (this.state[dataProp].damageDice === "");
        isActualDamageError = !isValid || (input === "");
      }

      if (isDamageDiceError || isActualDamageError)
        blankFade = true;

    } else {

      if (!isValid || (["ac", "dc"].includes(inputType) && (input === "")))
        blankFade = true;
    }

    let stateObj;

    if (blankFade) {

      stateObj = {

        [dataProp]: {
          ...this.state[dataProp],
          [inputType]: input,
          [errorStr]: !isValid,
          [errorMsgStr]: errorMsg
        }
      };

    } else {

      stateObj = {

        [dataProp]: {
          ...this.state[dataProp],
          [inputType]: input,
          [errorStr]: !isValid,
          [errorMsgStr]: errorMsg,
          displayImage: true,
          img: img,
          lastKeystroke: new Date()
        }
      };
    }

    this.setState(stateObj, () => {

      if (dataProp === "damageRollsData") {

        const damageDice = this.state[dataProp].damageDice;
        const actualDamage = this.state[dataProp].actualDamage;
        const isDamageDiceError = this.state[dataProp].isDamageDiceError;
        const isActualDamageError = this.state[dataProp].isActualDamageError;

        this.updateExpectedAndProbDamage(damageDice, actualDamage, isDamageDiceError, isActualDamageError);

      } else if (["ac", "dc"].includes(inputType)) {

        const totalMod = this.state[dataProp].totalMod;
        const isAcDcError = this.state[dataProp][errorStr];
        const adv = this.state[dataProp].adv;

        this.updateTotalRollAndSuccess(dataProp, totalMod, isAcDcError, input, adv);

      } else {

        this.updateTotalMod(dataProp, this.state[dataProp].statBonus,
          this.state[dataProp].proficiencyBonus,
          this.state[dataProp].otherBonus);
      }

      setTimeout(() => {

        if ((new Date() - this.state[dataProp].lastKeystroke) < 1500)
          return;

        this.setState({

          [dataProp]: {
            ...this.state[dataProp],
            displayImage: false
          }
        });

      }, 1500);
    });
  }

  updateAdvInput = event => {

    const dataProp = this.state.activeNav + "Data";

    const rng = Math.random();
    let img;

    if (rng < 0.25)
      img = lightning;
    else if (rng < 0.5)
      img = bicep;
    else if (rng < 0.75)
      img = wizHat;
    else
      img = d20;

    this.setState({

      [dataProp]: {
        ...this.state[dataProp],
        adv: event.target.value,
        displayImage: true,
        img: img,
        lastKeystroke: new Date()
      }

    }, () => {

      const acOrDc = (dataProp === "attackRollsData") ? "ac" : "dc";
      const acDc = this.state[dataProp][acOrDc];
      const errorStr = "is" + acOrDc.charAt(0).toUpperCase() + "cError";
      const isAcDcError = this.state[dataProp][errorStr];

      const totalMod = this.state[dataProp].totalMod;
      const adv = this.state[dataProp].adv;

      this.updateTotalRollAndSuccess(dataProp, totalMod, isAcDcError, acDc, adv);

      setTimeout(() => {

        if ((new Date() - this.state[dataProp].lastKeystroke) < 1500)
          return;

        this.setState({

          [dataProp]: {
            ...this.state[dataProp],
            displayImage: false
          }
        });

      }, 1500);
    });
  }

  updateTotalMod = (dataProp, statBonus, proficiencyBonus, otherBonus) => {

    const totalMod = calculators.calcTotalBonus(statBonus, proficiencyBonus, otherBonus);

    const acDcMap = {
      "abilityChecksData": ["dc", "Dc"],
      "savingThrowsData": ["dc", "Dc"],
      "attackRollsData": ["ac", "Ac"]
    };

    const isAcDcError = this.state[dataProp]["is" + acDcMap[dataProp][1] + "Error"];
    const acDc = this.state[dataProp][acDcMap[dataProp][0]];
    const adv = this.state[dataProp].adv;

    this.setState({

      [dataProp]: {
        ...this.state[dataProp],
        totalMod: totalMod
      }

    }, () => {
      this.updateTotalRollAndSuccess(dataProp, totalMod, isAcDcError, acDc, adv);
    });
  }

  updateTotalRollAndSuccess = (dataProp, totalMod, isAcDcError, acDc, adv) => {

    const totalRoll = calculators.calcExpectedRoll(totalMod, adv);
    const success = calculators.calcSuccess(dataProp, totalMod, isAcDcError, acDc, adv);

    this.setState({

      [dataProp]: {
        ...this.state[dataProp],
        totalRoll: totalRoll,
        success: success
      }
    });
  }

  updateExpectedAndProbDamage = (damageDice, actualDamage, isDamageDiceError, isActualDamageError) => {

    const expectedDamage = calculators.calcExpectedDamage(damageDice, isDamageDiceError);
    const dmgProb = calculators.calcProbDmg(damageDice, actualDamage, isDamageDiceError, isActualDamageError);

    this.setState({

      "damageRollsData": {
        ...this.state.damageRollsData,
        expectedDamage: expectedDamage,
        dmgPDF: dmgProb[0],
        dmgProbLess: dmgProb[1],
        dmgProbMore: dmgProb[2]
      }
    });
  }

  render() {

    const diceRollerPanel = <DiceRollerPanel data={this.state.diceRollerData}
                                             toggleProbabilities={this.toggleProbabilities}
                                             updateNumberOfDiceInput={this.updateNumberOfDiceInput}
                                             updateSidedDiceInput={this.updateSidedDiceInput}
                                             updateSignInput={this.updateSignInput}
                                             updateModifierInput={this.updateModifierInput}
                                             updateAdv={this.updateAdv}
                                             rollDice={this.rollDice} />;

    const abilityChecksPanel = <D20Panel data={this.state.abilityChecksData}
                                         updateTextInput={this.updateTextInput}
                                         updateAdvInput={this.updateAdvInput} />;

    const savingThrowsPanel = <D20Panel data={this.state.savingThrowsData}
                                        updateTextInput={this.updateTextInput}
                                        updateAdvInput={this.updateAdvInput} />;

    const attackRollsPanel = <D20Panel data={this.state.attackRollsData}
                                       updateTextInput={this.updateTextInput}
                                       updateAdvInput={this.updateAdvInput} />;

    const damageRollsPanel = <DamagePanel data={this.state.damageRollsData}
                                          updateTextInput={this.updateTextInput} />;

    const panel = {
      diceRoller: diceRollerPanel,
      abilityChecks: abilityChecksPanel,
      savingThrows: savingThrowsPanel,
      attackRolls: attackRollsPanel,
      damageRolls: damageRollsPanel
    };

    return (

      <>
        <NavBar activeNav={this.state.activeNav} updateNav={this.updateNav} />
        <div className={classes.Main}>
          {panel[this.state.activeNav]}
        </div>
      </>
    );
  }
}

export default Main;
