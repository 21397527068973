import * as msg from "./messages";
import * as c from "./constants";
import * as ev from "./expectedValues";
import * as utils from "./utils";

export const isValidBonus = (input, min, max) => {

  if (input === "") return [true, ""];

  if (input.search(/^(\+|-)?\d+$/g) === -1)
    return [false, msg.INVALID_BONUS_TERMS];

  if ((Number(input) < min) || (Number(input) > max)) {

    let minStr = (min >= 0) ? ("+" + min) : min;

    return [false, msg.INVALID_BONUS_RANGE + minStr + " and +" + max];
  }

  return [true, ""];
};

export const isValidOtherBonus = input => {

  if (input === "") return [true, ""];

  input = input.replace(/ /g, "");

  if (input.split(/[+-]/g).length > 4)
    return [false, msg.INVALID_OTHER_TERM_COUNT];

  const regex = /^(\+|-)?(\d+|[1-2]d4|1d(6|8|10|12))((\+|-)(\d+|[1-2]d4|1d(6|8|10|12))){0,2}$/g;

  if (input.search(regex) === -1)
    return [false, msg.INVALID_OTHER_TERMS];

  const d12 = input.match(/\dd12/g);

  if ((d12 !== null) && (d12.length > 1))
    return [false, msg.INVALID_OTHER_SINGLE_1d12];

  if (input.includes("-1d12"))
    return [false, msg.INVALID_OTHER_NEG_1d12];

  const d10 = input.match(/\dd10/g);

  if ((d10 !== null) && (d10.length > 1))
    return [false, msg.INVALID_OTHER_SINGLE_1d10];

  if (input.includes("-1d10"))
    return [false, msg.INVALID_OTHER_NEG_1d10];

  const d8 = input.match(/\dd8/g);

  if ((d8 !== null) && (d8.length > 1))
    return [false, msg.INVALID_OTHER_SINGLE_1d8];

  if (input.includes("-1d8"))
    return [false, msg.INVALID_OTHER_NEG_1d8];

  const d6 = input.match(/\dd6/g);

  if ((d6 !== null) && (d6.length > 1))
    return [false, msg.INVALID_OTHER_SINGLE_1d6];

  if (input.includes("-1d6"))
    return [false, msg.INVALID_OTHER_NEG_1d6];

  const negD4Count = input.match(/-1d4/g);

  if ((negD4Count !== null) && (negD4Count.length > 1))
    return [false, msg.INVALID_OTHER_SINGLE_NEG_1d4];

  const negD4 = input.match(/-[^1]d4/g);

  if (negD4 !== null)
    return [false, msg.INVALID_OTHER_NEG_1d4];

  const dx = input.match(/\dd\d/g);

  if ((dx !== null) && (dx.length > 1))
    return [false, msg.INVALID_OTHER_DICE];

  const sum = utils.extractOtherNumber(input);

  if ((sum < c.OTHER_CONSTANT_MIN) || (sum > c.OTHER_CONSTANT_MAX))
    return [false, msg.INVALID_OTHER_CONSTANT];

  return [true, ""];
};

export const isValidAcDc = input => {

  if (input === "") return [true, ""];

  return [(Number(input) >= 1) && (Number(input) <= 99), msg.INVALID_AC_DC];
};

export const isValidDamageDice = input => {

  if (input === "") return [true, ""];

  input = input.replace(/ /g, "");

  const regex = /^\d{1,2}d\d{1,2}((\+|-)\d{1,2})?$/g;

  if (input.search(regex) === -1)
    return [false, msg.INVALID_DAMAGE_FORMAT];

  const match = input.match(/\d{1,2}d(4|6|8|10|12)(\+|-|$)/g);

  if (match === null)
    return [false, msg.INVALID_DAMAGE_DICE];

  const dice = match[0];
  const idx = dice.indexOf("d");
  const numDice = Number(dice.substring(0, idx));
  let diceType = dice.substring(idx);

  if (diceType.match(/(\+|-)/g) !== null)
    diceType = diceType.substring(0, diceType.length - 1);

  if (numDice === 0)
    return [false, msg.INVALID_DAMAGE_ZERO];

  if (numDice > ev.diceCaps[diceType])
    return [false, msg.INVALID_NUMBER_OF_DICE + ev.diceCaps[diceType] + diceType + " is supported"];

  let constant = input.match(/(\+|-)\d{1,2}/g);

  if (constant !== null) {

    constant = Number(constant[0]);

    if ((constant > 30) || (constant < -30))
      return [false, msg.INVALID_DAMAGE_CONSTANT];
  }

  return [true, ""];
};

export const isValidActualDamage = input => {

  if (input === "") return [true, ""];

  if (input.search(/^\d{1,3}$/g) === -1)
    return [false, msg.INVALID_ACTUAL_DAMAGE];

  return [true, ""];
};
