import React from "react";

import classes from "./D20Panel.module.css";

const d20Panel = props => {

  const header = {
    abilityChecks: "Ability Checks",
    savingThrows: "Saving Throws",
    attackRolls: "Attack Rolls"
  };

  const acDc = {
    abilityChecks: ["DC:", "dc", "Dc"],
    savingThrows: ["DC:", "dc", "Dc"],
    attackRolls: ["AC:", "ac", "Ac"]
  };

  const successToHit = {
    abilityChecks: "Probability of success:",
    savingThrows: "Probability of success:",
    attackRolls: "Probability to hit:"
  };

  const statBonusError = (
    <p className={classes.Invalid}>{props.data.statBonusErrorMsg}</p>
  );

  const proficiencyBonusError = (
    <p className={classes.Invalid}>{props.data.proficiencyBonusErrorMsg}</p>
  );

  const otherBonusError = (
    <p className={classes.Invalid}>{props.data.otherBonusErrorMsg}</p>
  );

  const acDcError = (
    <p className={classes.Invalid}>{props.data[acDc[props.data.type][1] + "ErrorMsg"]}</p>
  );

  let leftImageClasses = [classes.LeftImage];
  let rightImageClasses = [classes.RightImage];

  if (!props.data.displayImage) {

    leftImageClasses.push(classes.FadeOutImage);
    rightImageClasses.push(classes.FadeOutImage);
  }

  leftImageClasses = leftImageClasses.join(" ");
  rightImageClasses = rightImageClasses.join(" ");

  return (

    <div className={classes.D20Panel}>
      <h4>{header[props.data.type]}</h4>
      <div className={classes.Item}>
        <label>Stat Modifier:</label>
        <input type="textbox"
               className={props.data.isStatBonusError ? classes.InputError : null}
               value={props.data.statBonus}
               data-input="statBonus"
               onChange={props.updateTextInput} />
        {props.data.isStatBonusError ? statBonusError : null}
      </div>
      <div className={classes.Item}>
        <label>Proficiency Bonus:</label>
        <input type="textbox"
               className={props.data.isProficiencyBonusError ? classes.InputError : null}
               value={props.data.proficiencyBonus}
               data-input="proficiencyBonus"
               onChange={props.updateTextInput} />
        {props.data.isProficiencyBonusError ? proficiencyBonusError : null}
      </div>
      <div className={classes.Item}>
        <label>Other Bonus:</label>
        <input type="textbox"
               placeholder="e.g., 1d4, or +1+1d6"
               className={props.data.isOtherBonusError ? classes.InputError : null}
               value={props.data.otherBonus}
               data-input="otherBonus"
               onChange={props.updateTextInput} />
        {props.data.isOtherBonusError ? otherBonusError : null}
      </div>
      <div className={classes.Line}></div>
      <div className={classes.Item}>
        <label>Total Modifier:</label>
        <input className={classes.Output} disabled value={props.data.totalMod} />
      </div>
      <div className={[classes.Item, classes.AcDcItem].join(" ")}>
        <label>{acDc[props.data.type][0]}</label>
        <input type="textbox"
               className={props.data["is" + acDc[props.data.type][2] + "Error"] ? classes.InputError : null}
               value={props.data[acDc[props.data.type][1]]}
               data-input={acDc[props.data.type][1]}
               onChange={props.updateTextInput} />
        {props.data["is" + acDc[props.data.type][2] + "Error"] ? acDcError : null}
      </div>
      <div className={classes.Item}>
        <label className={classes.AdvLabel}>Advantage / Disadvantage:</label>
        <select name="adv-disadv" value={props.data.adv} onChange={props.updateAdvInput}>
          <option value="default">None</option>
          <option value="advantage">Advantage</option>
          <option value="disadvantage">Disadvantage</option>
        </select>
      </div>
      <div className={classes.Line}></div>
      <div className={classes.Item}>
        <label>Expected total d20 roll:</label>
        <input className={classes.Output} disabled value={props.data.totalRoll} />
      </div>
      <div className={classes.Item}>
        <label>{successToHit[props.data.type]}</label>
        <div className={classes.OutputWrapper}>
          <img className={leftImageClasses} src={props.data.img} alt="" />
          <input className={classes.Output} value={props.data.success} disabled />
          <img className={rightImageClasses} src={props.data.img} alt="" />
        </div>
      </div>
    </div>
  );
};

export default d20Panel;
