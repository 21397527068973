import React, { Component } from "react";

import Banner from "../../components/Banner/Banner";
import Main from "../Main/Main";

class Home extends Component {

  render() {

    return (

      <>
        <Banner />
        <Main />
      </>
    );
  }
}

export default Home;
