import * as utils from "./utils";
import * as validators from "./validators";
import d20Distributions from "./distributions/d20Distributions";
import damageDistributions from "./distributions/damageDistributions";
import * as ev from "./expectedValues";
import * as c from "./constants";

export const d20PDF = (point, adv) => {
  return d20Distributions["1d20"][adv]["regular_dist"][String(point)].pdf;
};

export const d20CDF = (point, adv) => {
  return d20Distributions["1d20"][adv]["regular_dist"][String(point)].cdf;
};

export const xdyPDF = (point, numberOfDice, sidedDice) => {
  return damageDistributions["d" + sidedDice + "_dmg"][numberOfDice + "d" + sidedDice][String(point)].pdf;
};

export const xdyCDF = (point, numberOfDice, sidedDice) => {
  return damageDistributions["d" + sidedDice + "_dmg"][numberOfDice + "d" + sidedDice][String(point)].cdf;
};

export const calcTotalBonus = (statBonus, proficiencyBonus, otherBonus) => {

  let bonus = 0;
  let output = "+0";

  if (validators.isValidBonus(statBonus, c.STAT_MOD_MIN, c.STAT_MOD_MAX)[0] && statBonus)
    bonus += Number(statBonus);

  if (validators.isValidBonus(proficiencyBonus, c.PROF_BONUS_MIN, c.PROF_BONUS_MAX)[0] && proficiencyBonus)
    bonus += Number(proficiencyBonus);

  if (validators.isValidOtherBonus(otherBonus)[0] && otherBonus)
    bonus += utils.extractOtherNumber(otherBonus);

  if (bonus > 0)
    output = "+" + bonus;
  else if (bonus < 0)
    output = String(bonus);

  if (validators.isValidOtherBonus(otherBonus)[0] && otherBonus) {

    output += utils.extractOtherDice(otherBonus);

    if (!bonus)
      output = output.substring(2);
  }

  return output;
};

export const calcExpectedRoll = (totalMod, adv) => {

  const constMod = totalMod.replace(/(\+|-)?\dd\d{1,2}/g, "");

  const constantStr = constMod.match(/(\+|-)?\d{1,3}/g);
  let constant = constantStr ? Number(constantStr[0]) : 0;

  const d4Mod = totalMod.replace(/-1d4/g, "");
  const d4Str = d4Mod.match(/\dd4/g);
  const xd4 = d4Str ? Number(d4Str[0][0]) : 0;

  const d4Ev = xd4 * ev.D4_EV;
  const negD4Ev = totalMod.includes("-1d4") ? ev.NEG_D4_EV : 0;
  const d6Ev = totalMod.includes("1d6") ? ev.D6_EV : 0;
  const d8Ev = totalMod.includes("1d8") ? ev.D8_EV : 0;
  const d10Ev = totalMod.includes("1d10") ? ev.D10_EV : 0;
  const d12Ev = totalMod.includes("1d12") ? ev.D12_EV : 0;

  const expectedRoll = (ev.expectedD20Base[adv] + constant + d4Ev +
    negD4Ev + d6Ev + d8Ev + d10Ev + d12Ev).toFixed(1);

  return expectedRoll;
};

export const calcSuccess = (type, totalMod, isAcDcError, acDc, adv) => {

  if (isAcDcError || (acDc === ""))
    return "";

  const constMod = totalMod.replace(/(\+|-)?\dd\d{1,2}/g, "");

  const constantStr = constMod.match(/(\+|-)?\d{1,3}/g);
  let constant = constantStr ? Number(constantStr[0]) : 0;

  const d4Mod = totalMod.replace(/-1d4/g, "");
  const d4Str = d4Mod.match(/\dd4/g);
  const xd4 = d4Str ? Number(d4Str[0][0]) : 0;

  const negD4 = totalMod.includes("-1d4");
  const d6 = totalMod.includes("1d6");
  const d8 = totalMod.includes("1d8");
  const d10 = totalMod.includes("1d10");
  const d12 = totalMod.includes("1d12");

  let distribution = "1d20";

  if (xd4 === 1)
    distribution = "1d20+1d4";
  else if (xd4 === 2)
    distribution = "1d20+2d4";
  else if (negD4)
    distribution = "1d20-1d4";
  else if (d6)
    distribution = "1d20+1d6";
  else if (d8)
    distribution = "1d20+1d8";
  else if (d10)
    distribution = "1d20+1d10";
  else if (d12)
    distribution = "1d20+1d12";

  let target = acDc - constant;
  target = Math.min(target, 45);
  target = Math.max(target, -30);

  const distObj = d20Distributions[distribution][adv]["regular_dist"][String(target)];
  let success = (1. - Number(distObj["cdf"])) + Number(distObj["pdf"]);

  if (type === "attackRollsData") {

    let nat20Dist = d20Distributions[distribution][adv]["nat20_dist"][String(target - 1)];
    let nat1Dist = d20Distributions[distribution][adv]["nat1_dist"][String(target)];

    success += Number(nat20Dist["cdf"]);
    success -= Number(nat1Dist["reverse_cdf"]);
  }

  success = Math.max(success, 0.);

  const testSuccess = Number(success.toFixed(10));
  let percentageSuccess;
  const successToFixed1 = (success * 100).toFixed(1);

  if (testSuccess === 0.)
    percentageSuccess = "0.0%";
  else if (testSuccess === 1.)
    percentageSuccess = "100.0%";
  else if ((successToFixed1 === "0.0") || (successToFixed1 === "100.0"))
    percentageSuccess = (success * 100).toFixed(10) + "%";
  else
    percentageSuccess = successToFixed1 + "%";

  return percentageSuccess;
};

export const calcExpectedDamage = (input, isDamageDiceError) => {

  if (isDamageDiceError || (input === ""))
    return "";

  const damageDice = input.match(/\d{1,2}d\d{1,2}/g)[0];
  const idx = damageDice.indexOf("d");
  const numDice = Number(damageDice.substring(0, idx));
  const diceType = damageDice.substring(idx);

  let constant = input.match(/(\+|-)\d{1,2}/g);
  constant = (constant === null) ? 0 : Number(constant[0]);

  const expectedDamage = (numDice * ev.damageEv[diceType]) + constant;

  if (expectedDamage < 0.)
    return "0.0";
  else
    return expectedDamage.toFixed(1);
};

export const calcProbDmg = (rolledDice, actualDamage,
  isDamageDiceError, isActualDamageError) => {

  if (isDamageDiceError || isActualDamageError
    || (rolledDice === "") || (actualDamage === "")) {

    return ["", "", ""];
  }

  const damageDice = rolledDice.match(/\d{1,2}d\d{1,2}/g)[0];
  const idx = damageDice.indexOf("d");
  const numDice = Number(damageDice.substring(0, idx));
  const diceType = damageDice.substring(idx);
  const diceTypeNum = Number(damageDice.substring(idx + 1));

  let constant = rolledDice.match(/(\+|-)\d{1,2}/g);
  constant = (constant === null) ? 0 : Number(constant[0]);
  const actualDmg = Number(actualDamage) - constant;

  const dist = damageDistributions[diceType + "_dmg"][damageDice];
  const dmgObj = dist[String(actualDmg)];
  let dmgPDF, probLessDmg, probMoreDmg;

  if (actualDmg <= 0) {

    dmgPDF = 0.;
    probLessDmg = 0.;
    probMoreDmg = 1.;

  } else {

    dmgPDF = (dmgObj === undefined) ? 0. : Number(dmgObj["pdf"]);
    probLessDmg = (dmgObj === undefined) ? 1. : Number(dmgObj["cdf"]);
    probMoreDmg = (dmgObj === undefined) ? 0. : (1. - probLessDmg);
  }

  if ((dmgObj !== undefined) && (actualDamage === "0"))
    dmgPDF = probLessDmg;

  dmgPDF = Math.max(dmgPDF, 0.);
  probLessDmg = Math.max(probLessDmg, 0.);
  probMoreDmg = Math.max(probMoreDmg, 0.);

  const minDmg = numDice;
  const maxDmg = numDice * diceTypeNum;
  const dmgPDFToFixed1 = (dmgPDF * 100).toFixed(1);
  const probLessDmgToFixed1 = (probLessDmg * 100).toFixed(1);
  const probMoreDmgToFixed1 = (probMoreDmg * 100).toFixed(1);

  if ((dmgPDFToFixed1 === "0.0") || (dmgPDFToFixed1 === "100.0"))
    dmgPDF = (dmgPDF * 100).toFixed(10) + "%";
  else
    dmgPDF = dmgPDFToFixed1 + "%";

  if (actualDmg < minDmg)
    probLessDmg = "Out of range";
  else if ((probLessDmgToFixed1 === "0.0") || (probLessDmgToFixed1 === "100.0"))
    probLessDmg = (probLessDmg * 100).toFixed(10) + "%";
  else
    probLessDmg = probLessDmgToFixed1 + "%";

  if ((actualDmg > maxDmg) && (actualDamage !== "0"))
    probMoreDmg = "Out of range";
  else if ((actualDmg === maxDmg) || ((actualDamage === "0") && (actualDmg >= maxDmg))) {

    probLessDmg = "100.0%";
    probMoreDmg = "0.0%";

    if ((actualDamage === "0") && (actualDmg >= maxDmg))
      dmgPDF = "100.0%";
  }
  else if ((probMoreDmgToFixed1 === "0.0") || (probMoreDmgToFixed1 === "100.0"))
    probMoreDmg = (probMoreDmg * 100).toFixed(10) + "%";
  else
    probMoreDmg = probMoreDmgToFixed1 + "%";

  if (probLessDmg === "Out of range") {

    dmgPDF = "Out of range";
    probMoreDmg = "Out of range";
  }

  if (probMoreDmg === "Out of range") {

    dmgPDF = "Out of range";
    probLessDmg = "Out of range";
  }

  return [dmgPDF, probLessDmg, probMoreDmg];
};
