import React from "react";

import classes from "./DiceRollerPanel.module.css";

const maxNumberOfDiceMap = {
  "4": 15,
  "6": 12,
  "8": 10,
  "10": 9,
  "12": 8,
  "20": 1,
  "100": 1
};

const diceRollerPanel = props => {

  const showBreakdown = (props.data.result !== "")
    && ((props.data.modifier !== "0")
      || ["advantage", "disadvantage"].includes(props.data.adv)
      || (props.data.numberOfDice > 1));

  let result1Loses = false;

  if (props.data.rawResult2 !== "") {

    result1Loses = (
      ((props.data.adv === "advantage") && (Number(props.data.rawResult1) < Number(props.data.rawResult2)))
      || ((props.data.adv === "disadvantage") && (Number(props.data.rawResult1) > Number(props.data.rawResult2)))
    );
  }

  const openPar = (props.data.numberOfDice > 1) ? "(" : "";
  const closePar = (props.data.numberOfDice > 1) ? ")" : "";

  const printedResult = openPar
    + (props.data.resultBreakdown || props.data.rawResult1) + closePar;

  const result1 = (

    <div className={result1Loses ? classes.Strikethrough : null}>
      {printedResult + " " + props.data.sign + " " + Math.abs(props.data.numericModifier) + " ="}
    </div>
  );

  let result2 = null;

  if (["advantage", "disadvantage"].includes(props.data.adv)) {

    result2 = (

      <div className={!result1Loses ? classes.Strikethrough : null}>
        <br />
        {props.data.rawResult2 + " " + props.data.sign + " " + Math.abs(props.data.numericModifier) + " ="}
      </div>
    );
  }

  const breakdownClasses = [classes.Breakdown];

  if (result2) {
    breakdownClasses.push(classes.DoubleResult);
  } else {
    breakdownClasses.push(classes.SingleResult);
  }

  const breakdown = (

    <div className={breakdownClasses.join(" ")}>
      {result1}
      {result2}
    </div>
  );

  let leftImageClasses = [classes.LeftImage];
  let rightImageClasses = [classes.RightImage];

  if (!props.data.displayImage) {

    leftImageClasses.push(classes.FadeOutImage);
    rightImageClasses.push(classes.FadeOutImage);
  }

  leftImageClasses = leftImageClasses.join(" ");
  rightImageClasses = rightImageClasses.join(" ");

  const probabilitiesToDisplay = (

    <>
      <strong>exactly&nbsp;{props.data.result}:&nbsp;</strong>{props.data.pdf}
      <br />
      <strong className={classes.Indent1}>&lt;&#61;&nbsp;{props.data.result}:&nbsp;</strong>{props.data.lessThanPercent}
      <br />
      <strong className={classes.Indent2}>&gt;&nbsp;{props.data.result}:&nbsp;</strong>{props.data.greaterThanPercent}
    </>
  );

  const noProbabilitiesToDisplay = (
    <p>Waiting for roll...</p>
  );

  const probabilities = (

    <div className={classes.Probabilities}>
      <strong className={classes.Underline}>Probability to Roll:</strong>
      <br /><br />
      {(props.data.result === "") ? noProbabilitiesToDisplay : probabilitiesToDisplay}
    </div>
  );

  const maxNumberOfDice = maxNumberOfDiceMap[String(props.data.sidedDiceInput)];
  const numberOfDiceOptions = [];

  for (let i = 1; i <= maxNumberOfDice; i++)
    numberOfDiceOptions.push(<option key={i} value={String(i)}>{i}</option>);

  const modifierOptions = [];

  for (let i = 0; i < 31; i++) {
    modifierOptions.push(<option key={i} value={String(i)}>{i}</option>);
  }

  const advItem = (

    <div className={classes.Item}>
      <label className={[classes.Label, classes.SideLabel].join(" ")}>Advantage / Disadvantage:</label>
      <div className={classes.SpacingDiv}>
        <select
          className={classes.Adv}
          name="adv"
          value={props.data.advInput}
          onChange={props.updateAdv}
        >
          <option value="default">None</option>
          <option value="advantage">Advantage</option>
          <option value="disadvantage">Disadvantage</option>
        </select>
      </div>
    </div>
  );

  return (

    <div className={classes.DiceRollerPanel}>
      <h4>Roll the Dice</h4>
      <div className={classes.ResultWrapper}>
        {showBreakdown ? breakdown : null}
        <p className={classes.ToggleProbabilities} onClick={props.toggleProbabilities}>
          {props.data.showProbabilities ? "Hide " : "Show "}Probabilities
        </p>
        {props.data.showProbabilities ? probabilities : null}
        <img className={leftImageClasses} src={props.data.img} alt="" />
        <div className={classes.Result}>
          <div className={classes.ResultInner}>{props.data.result}</div>
        </div>
        <img className={rightImageClasses} src={props.data.img} alt="" />
      </div>
      <div className={classes.Item}>
        <label className={[classes.Label, classes.SideLabel].join(" ")}>Dice to Roll:</label>
        <div className={classes.SpacingDiv}>
          <select
            className={classes.NumberOfDice}
            name="numberOfDice"
            value={String(props.data.numberOfDiceInput)}
            onChange={props.updateNumberOfDiceInput}
            disabled={[20, 100].includes(props.data.sidedDiceInput)}
          >
            {numberOfDiceOptions}
          </select>
          <label className={classes.NumberOfDiceLabel}># of dice</label>
          <p className={classes.D}>d</p>
          <select
            className={classes.SidedDice}
            name="sidedDice"
            value={String(props.data.sidedDiceInput)}
            onChange={props.updateSidedDiceInput}
          >
            <option value="4">4</option>
            <option value="6">6</option>
            <option value="8">8</option>
            <option value="10">10</option>
            <option value="12">12</option>
            <option value="20">20</option>
            <option value="100">100</option>
          </select>
          <label className={classes.SidedDiceLabel}>sided dice</label>
        </div>
      </div>
      <div className={classes.Item}>
        <label className={[classes.Label, classes.SideLabel].join(" ")}>Modifier:</label>
        <div className={classes.SpacingDiv}>
          <select
            className={classes.Sign}
            name="sign"
            value={props.data.signInput}
            onChange={props.updateSignInput}
          >
            <option value="+">+</option>
            <option value="-">-</option>
          </select>
          <select
            className={classes.Modifier}
            name="modifier"
            value={props.data.modifierInput}
            onChange={props.updateModifierInput}
          >
            {modifierOptions}
          </select>
        </div>
      </div>
      {(props.data.sidedDiceInput === 20) ? advItem : null}
      <div className={classes.Item}>
        <div className={classes.ButtonWrapper} onClick={props.rollDice}>
          <div className={classes.Button}>
            <div>Roll {props.data.numberOfDiceInput + 'd' + props.data.sidedDiceInput}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default diceRollerPanel;
