export const extractOtherNumber = input => {

  input = input.replace(/ /g, "");
  input = input.replace(/(\+|-)?\dd\d{1,2}/g, "");

  const constants = input.match(/(\+|-)?\d+/g);
  let sum = 0;

  if (constants) {

    for (let constant of constants)
      sum += Number(constant);
  }

  return sum;
};

export const extractOtherDice = input => {

  let dice = "";
  input = input.replace(/ /g, "");

  const d4 = input.match(/(^|\+)[1-2]d4/g);
  let d4Sum = 0;

  if (d4 !== null) {

    for (let d4Num of d4) {

      if (d4Num[0] === "+")
        d4Sum += Number(d4Num[1]);
      else
        d4Sum += Number(d4Num[0]);
    }

    dice += "+" + d4Sum + "d4";
  }

  if (input.includes("-1d4"))
    dice += "-1d4";

  if (input.includes("1d6"))
    dice += "+1d6";

  if (input.includes("1d8"))
    dice += "+1d8";

  if (input.includes("1d10"))
    dice += "+1d10";

  if (input.includes("1d12"))
    dice += "+1d12";

  return dice;
};
