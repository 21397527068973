import d4Dist from "./DamageDistributions/d4_dmg_distributions";
import d6Dist from "./DamageDistributions/d6_dmg_distributions";
import d8Dist from "./DamageDistributions/d8_dmg_distributions";
import d10Dist from "./DamageDistributions/d10_dmg_distributions";
import d12Dist from "./DamageDistributions/d12_dmg_distributions";
import d100Dist from "./DamageDistributions/d100_dmg_distributions";

const distributions = {
  "d4_dmg": d4Dist,
  "d6_dmg": d6Dist,
  "d8_dmg": d8Dist,
  "d10_dmg": d10Dist,
  "d12_dmg": d12Dist,
  "d100_dmg": d100Dist
};

export default distributions;
