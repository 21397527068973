import React from "react";

import classes from "./NavBar.module.css";

const navBar = props => {

  const navClasses = {
    diceRoller: [classes.NavItem],
    abilityChecks: [classes.NavItem],
    savingThrows: [classes.NavItem],
    attackRolls: [classes.NavItem],
    damageRolls: [classes.NavItem]
  };

  navClasses[props.activeNav].push(classes.Active);

  for (let nav in navClasses)
    navClasses[nav] = navClasses[nav].join(" ");

  return (

    <div className={classes.NavBar}>
      <div className={navClasses.diceRoller}
           id="diceRoller"
           onClick={props.updateNav}>
            Dice Roller
      </div>
      <div className={navClasses.abilityChecks}
           id="abilityChecks"
           onClick={props.updateNav}>
            Ability Checks
      </div>
      <div className={navClasses.savingThrows}
           id="savingThrows"
           onClick={props.updateNav}>
            Saving Throws
      </div>
      <div className={navClasses.attackRolls}
           id="attackRolls"
           onClick={props.updateNav}>
            Attack Rolls
      </div>
      <div className={navClasses.damageRolls}
           id="damageRolls"
           onClick={props.updateNav}>
            Damage Rolls
      </div>
    </div>
  );
};

export default navBar;
