import React from "react";

import classes from "./DamagePanel.module.css";

const damagePanel = props => {

  const damageDiceError = (
    <p className={classes.Invalid}>{props.data.damageDiceErrorMsg}</p>
  );

  const actualDamageError = (
    <p className={classes.Invalid}>{props.data.actualDamageErrorMsg}</p>
  );

  let leftImageClasses = [classes.LeftImage];
  let rightImageClasses = [classes.RightImage];

  if (!props.data.displayImage) {

    leftImageClasses.push(classes.FadeOutImage);
    rightImageClasses.push(classes.FadeOutImage);
  }

  leftImageClasses = leftImageClasses.join(" ");
  rightImageClasses = rightImageClasses.join(" ");

  return (

    <div className={classes.DamagePanel}>
      <h4>Damage Rolls</h4>
      <div className={classes.Item}>
        <label>Damage dice + mod:</label>
        <input type="textbox"
               placeholder="e.g., 2d6 + 2"
               className={props.data.isDamageDiceError ? classes.InputError : null}
               value={props.data.damageDice}
               data-input="damageDice"
               onChange={props.updateTextInput} />
        {props.data.isDamageDiceError ? damageDiceError : null}
      </div>
      <div className={classes.Item}>
        <label>Actual damage rolled:</label>
        <input type="textbox"
               className={props.data.isActualDamageError ? classes.InputError : null}
               value={props.data.actualDamage}
               data-input="actualDamage"
               onChange={props.updateTextInput} />
        {props.data.isActualDamageError ? actualDamageError : null}
      </div>
      <div className={classes.Line}></div>
      <div className={classes.Item}>
        <label>Expected damage:</label>
        <input className={classes.Output} value={props.data.expectedDamage} disabled />
      </div>
      <div className={classes.Item}>
        <p className={classes.ProbOfRolling}>Probability of rolling:</p>
      </div>
      <div className={classes.Item}>
        <label>Actual damage rolled:</label>
        <div className={classes.OutputWrapper}>
          <img className={leftImageClasses} src={props.data.img} alt="" />
          <input className={classes.Output} value={props.data.dmgPDF} disabled />
          <img className={rightImageClasses} src={props.data.img} alt="" />
        </div>
      </div>
      <div className={classes.Item}>
        <label>Actual damage or less:</label>
        <div className={classes.OutputWrapper}>
          <img className={leftImageClasses} src={props.data.img} alt="" />
          <input className={classes.Output} value={props.data.dmgProbLess} disabled />
          <img className={rightImageClasses} src={props.data.img} alt="" />
        </div>
      </div>
      <div className={classes.Item}>
        <label><div className={classes.GreaterThanLabel}>Greater than actual damage:</div></label>
        <div className={classes.OutputWrapper}>
          <img className={leftImageClasses} src={props.data.img} alt="" />
          <input className={classes.Output} value={props.data.dmgProbMore} disabled />
          <img className={rightImageClasses} src={props.data.img} alt="" />
        </div>
      </div>
    </div>
  );
};

export default damagePanel;
