import * as c from "./constants";

export const INVALID_BONUS_TERMS = "Invalid input: valid format is +x, -x, or x";
export const INVALID_BONUS_RANGE = "Invalid input: must be within ";
export const INVALID_OTHER_TERM_COUNT = "Invalid input: a max of three terms are supported";
export const INVALID_OTHER_TERMS = "Invalid input: valid terms are +/-x, +1d[x], +2d4, or -1d4";
export const INVALID_OTHER_SINGLE_1d12 = "Invalid input: only a single +1d12 term is supported";
export const INVALID_OTHER_SINGLE_1d10 = "Invalid input: only a single +1d10 term is supported";
export const INVALID_OTHER_SINGLE_1d8 = "Invalid input: only a single +1d8 term is supported";
export const INVALID_OTHER_SINGLE_1d6 = "Invalid input: only a single +1d6 term is supported";
export const INVALID_OTHER_SINGLE_NEG_1d4 = "Invalid input: only a single -1d4 term is supported";
export const INVALID_OTHER_NEG_1d4 = "Invalid input: only -1d4 is supported";
export const INVALID_OTHER_NEG_1d12 = "Invalid input: only +1d12 is supported";
export const INVALID_OTHER_NEG_1d10 = "Invalid input: only +1d10 is supported";
export const INVALID_OTHER_NEG_1d8 = "Invalid input: only +1d8 is supported";
export const INVALID_OTHER_NEG_1d6 = "Invalid input: only +1d6 is supported";
export const INVALID_OTHER_DICE = "Invalid input: only one dice term is supported (+1d[x], +2d4, or -1d4)";
export const INVALID_OTHER_CONSTANT = "Invalid input: constant term must be within " + c.OTHER_CONSTANT_MIN + " and +" + c.OTHER_CONSTANT_MAX;
export const INVALID_AC_DC = "Invalid input: valid input is 1 - 99";
export const INVALID_DAMAGE_FORMAT = "Invalid input: valid format is [x]d[x] +/- [x]";
export const INVALID_DAMAGE_DICE = "Invalid input: only d4, d6, d8, d10, and d12 are supported";
export const INVALID_DAMAGE_ZERO = "Invalid input: must have one or more damage dice";
export const INVALID_NUMBER_OF_DICE = "Invalid input: only up to ";
export const INVALID_DAMAGE_CONSTANT = "Invalid input: constant term must be within -30 and +30"
export const INVALID_ACTUAL_DAMAGE = "Invalid input: valid input is 0 - 999";
