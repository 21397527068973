export const D4_EV = 2.5;
export const NEG_D4_EV = -2.5;
export const D6_EV = 3.5;
export const D8_EV = 4.5;
export const D10_EV = 5.5;
export const D12_EV = 6.5;

export const expectedD20Base = {
  "default": 10.5,
  "advantage": 13.825,
  "disadvantage": 7.175
};

export const damageEv = {
  "d4": 2.5,
  "d6": 3.5,
  "d8": 4.5,
  "d10": 5.5,
  "d12": 6.5
};

export const diceCaps = {
  "d4": 15,
  "d6": 12,
  "d8": 10,
  "d10": 9,
  "d12": 8
};
